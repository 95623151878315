import React, {useState} from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import "../../assets/custom-nara.css"

const LinkTreeComponent = ({SocialData = []}) => {
    return (
        <>
            <div className="section section-padding-equal">
                <div className="container">
                    {/*<div className="linktree-avatar-div">*/}
                    {/*    <img src={process.env.PUBLIC_URL + "/images/nara/headshot1.png"} alt="Shape"*/}
                    {/*         className="linktree-avatar-img"/>*/}
                    {/*</div>*/}
                    <SectionTitle
                        subtitle="Explore my channels for professional insights, collaborations, and more."
                        title={`Stay Connected with ${process.env.REACT_APP_OWNER.split(" ")[0]}`}
                        description=""
                        textAlignment=""
                        textColor=""
                    />
                    <div className="about-expert">
                        <div className="linktree-list">
                            {SocialData.map((item) => {
                                return (
                                    <a
                                        key={item.id}
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="linktree-button"
                                    >
                                        {/* Thumbnail Image (left-aligned) */}
                                        <img
                                            src={`/images/socials/linktree/${item.linktree_thumbnail}.png`}
                                            alt={item.linktree_title}
                                            className="linktree-thumbnail-img"
                                        />
                                        {/* Text for the Link */}
                                        <span className="linktree-text">
                                {item.linktree_title || 'Visit Link'}
                            </span>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {/* Example shapes or background images */}
                <ul className="shape-group-1 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                       alt="bubble"/></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                                                       alt="bubble"/></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
                                                       alt="bubble"/></li>
                    <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                                                       alt="bubble"/></li>
                </ul>
            </div>
        </>
    );
};

export default LinkTreeComponent;
